import React from 'react';
import { graphql } from 'gatsby';
import {Helmet} from 'react-helmet';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import PageContext from 'contexts/PageContext';

let options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return `<figure class="image is-128x128"><img src="${node.data.target.fields.file.url}" /></figure>`;
    },
  },
};

const About = ({ pageContext, ...props }) => {
  const {
    data: {
      allContentfulAboutPage: { nodes },
    },
  } = props;

  const title = nodes[0].title;
  const content = documentToHtmlString(
    JSON.parse(nodes[0].childContentfulAboutPageContentRichTextNode.content),
    options
  );

  return (
    <PageContext.Provider value={pageContext}>

      <Helmet title={`${title} - Marcin Madej`} />

      <section className="card section">
        <h1
          className="title is-1"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <section
          className="content is-medium"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </section>
    </PageContext.Provider>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulAboutPage {
      nodes {
        title
        childContentfulAboutPageContentRichTextNode {
          content
        }
      }
    }
  }
`;

export default About;
